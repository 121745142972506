import "./App.css";
import HomeSection from "./Components/homeSection/HomeSection";
import Navbar from "./Components/navbar/Navbar";
import "antd/dist/antd.css";
import HowWorkSection from "./Components/howWorkSection/HowWorkSection";
import "aos/dist/aos.css";
import AOS from "aos";
import { Suspense, useEffect } from "react";
import WhoAreWe from "./Components/whoAreWe/WhoAreWe";
import Feature from "./Components/features/Feature";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import ContactUs from "./Components/contactUs/contactUs";
import "leaflet/dist/leaflet.css";
import Subscription from "./Components/subscription/Subscription";
import Footer from "./Components/footer/Footer";
import OurGoal from "./Components/ourGoal/OurGoal";
import OurVision from "./Components/ourVision/OurVision";
import MrsService from "./Components/mrsService/MrsService";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({});
    AOS.refresh();
  }, []);

  return (
    <>
      <Navbar />
      <HomeSection />
      <Feature />
      <WhoAreWe />
      <OurVision />
      <OurGoal />
      <HowWorkSection />
      <MrsService />
      <Subscription />
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
