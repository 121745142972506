import { Row, Col, Button, Image } from "antd";
import OurVisionImage from "../icons/OurVisionImage";
import Classes from "./ourVision.module.css";
import './ourvision.css';
import i18n  from "i18next";

const OurVision = () => {
  return( 
  i18n.language === 'ar'?  
  

    
    <div className="App-Container" id="ourVision">
      <div
        className={`${Classes.WhoAreWeSection} `}
        id="ourVision"
      >
        <Row
        className={`${Classes.HeaderSectionParagraph}` } >
          <p style={{fontSize: '32px'}}>
            {" "}
            رؤيتنا
          </p>
        </Row>
        <Row className={`${Classes.HeaderSectionContainer} ourvision` }>
          <Col
              sm={24}
              xs={24}
              md={24}
              lg={12}
              data-aos="fade-up"
              data-aos-delay="40"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-cubic"
              className="image-about"
          >
            <div className={Classes.ImageContainer}>
              <OurVisionImage></OurVisionImage>
            </div>
          </Col>

          <Col 
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className={Classes.HeaderSectionContent}
            data-aos="fade-right"
            data-aos-delay="40"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            style={{ overflow: "hidden" }}
          >
            {" "}
            <p className={Classes.Paragraph}  style={{direction: 'rtl', textAlign: 'justify', fontFamily: 'Cairo'}}>
              بطموح متميز يعانق الأفق يدعمه خبرات فريق عمل مؤهل يتميز بالفاعلية والتركيز على بلوغ الأهداف يتسم بخبرة طويلة ومعرفة واسعة انطلقت MetaSign بفكرها الإنساني لتسهيل حياة من لديهم صعوبات في التواصل السمعي أو الكلامي لبلوغ ما وصل إليه العالم من التطور في مجال التكنولوجيا والتواصل الاجتماعي.
            </p>
          </Col>

        </Row>
      </div>
    </div>
    
    
    
    :
    <div className="App-Container" id="ourVision">
      <div
        className={`${Classes.WhoAreWeSection} `}
        id="ourVision"
      >
        <Row
        className={`${Classes.HeaderSectionParagraph}` } >
          <p style={{fontSize: '32px'}}>
            {" "}
            Vision
          </p>
        </Row>
        <Row className={`${Classes.HeaderSectionContainer} ourvision` }>
          <Col
              sm={24}
              xs={24}
              md={24}
              lg={12}
              data-aos="fade-up"
              data-aos-delay="40"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-cubic"
              className="image-about"
          >
            <div className={Classes.ImageContainer}>
              <OurVisionImage></OurVisionImage>
            </div>
          </Col>

          <Col 
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className={Classes.HeaderSectionContent}
            data-aos="fade-right"
            data-aos-delay="40"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            style={{ overflow: "hidden" }}
          >
            {" "}
            <p className={Classes.Paragraph}  style={{ textAlign: 'justify', fontFamily: 'Cairo'}}>
            With a distinct ambition that embraces the horizon, supported by the expertise of a qualified work team
characterized by effectiveness, and focus on achieving goals, long experience and extensive knowledge,
MetaSign launched with its humanitarian thought to facilitate the lives of those who have difficulties in
verbal communication, to reach the world's development in the field of technology and social
communication.
            </p>
          </Col>

        </Row>
      </div>
    </div>
   )
  
};

export default OurVision;
