import { Row, Col, Button, Image, Card } from "antd";
import Easy from '../icons/Easy';
import Classes from "./feature.module.css";
import video_to_audio from '../icons/video_to_audio.png';
import security from '../icons/security.png';
import direct_call from '../icons/direct_call.png';
import easy_to_create_an_account from '../icons/easy_to_create_an_account.png';
import call_interpreator from '../icons/call_interpreator.png';
import conference_call from '../icons/conference_call.png';
import i18n  from "i18next";

const Feature = () => {
  return( 
    i18n.language === 'ar'? 
      <div className="App-Container">
        <div className={Classes.FeatureSection} id="features">
          <div className={Classes.HeaderSectionParagraph}>
            <p> ميزات النظام</p>
          </div>

          <Row className={Classes.CardDiv}>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-up"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-phone"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '44%'}}
                ></i> */}

                <div>
                <img src ={easy_to_create_an_account}
                className={'feature-image'}
                style={{
                  marginBottom: '15px',
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '44%'}}
                alt = "video_to_audio" />

                <p className={Classes.antCardParagraph} style={{direction: 'rtl', marginTop: '15px'}}>
                  سهولة إنشاء الحساب في التطبيق للاستفادة من العروض الحصرية المتاحة
                </p>
                </div>
                
              </Card>
            </Col>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-up"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-home"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

            <img src ={direct_call}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '43%'}}
                alt = "video_to_audio" />



                <p className={Classes.antCardParagraph} style={{direction: 'rtl', marginTop: '15px'}}>
                  اتصال مباشر وسريع مع مترجمي لغة إشارة معتمدين في أي وقت وأي مكان
                </p>
              </Card>
            </Col>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fas fa-video"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}
            <img src ={conference_call}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '43%'}}
                alt = "video_to_audio" />



                <p className={Classes.antCardParagraph} style={{direction: 'rtl', marginTop: '15px'}}>
                  اتصال جماعي بين متحدث بلغة الإشارة وآخر بلغة الكلام بينهما مترجم لغة إشارة
                </p>
              </Card>
            </Col>
            

            <Col
                className={Classes.antcard}
                
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false} style={{padding: '10px !important'}}>
                {/* <i
                    className="fa fa-globe"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

                
            <img src ={call_interpreator}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '43%'}}
                alt = "call_interpreator" />

                <p className={Classes.antCardParagraph} style={{direction: 'rtl', marginTop: '0px'}}>
                تواصل مع شركاء MetaSign في أي وقت للاستفادة من العروض المقدمة في التطبيق أو لإجابتك عن أي استفسار
                </p>
              </Card>
            </Col>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-globe"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

                
            <img src ={video_to_audio}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '45%'}}
                alt = "video_to_audio" />

                <p className={Classes.antCardParagraph} style={{marginTop: '0px'}}>
                تحويل مقاطع الفيديو بلغة الإشارة إلى رسائل  صوتية -والعكس- حيث يمكنك مشاركتها مع من تحب في أي تطبيق من وسائل التواصل الاجتماعي.
                </p>
              </Card>
            </Col>

          

            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-globe"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

                
            <img src ={security}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top:'25px',
                  right: '43%'
                }}
                alt = "security" />

                <p className={Classes.antCardParagraph} style={{marginTop: '0px'}}>
                  الخصوصية والأمان لمعلوماتك وبياناتك الشخصية.
                </p>
              </Card>
            </Col>


          </Row>
          
        </div>
      </div>

      :

      <div className="App-Container">
        <div className={Classes.FeatureSection} id="features">
          <div className={Classes.HeaderSectionParagraph}>
            <p>  System Features </p>
          </div>

          <Row className={Classes.CardDiv}>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-up"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-phone"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '44%'}}
                ></i> */}

                <div>
                <img src ={easy_to_create_an_account}
                className={'feature-image'}
                style={{
                  marginBottom: '15px',
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '44%'}}
                alt = "video_to_audio" />

                <p className={Classes.antCardParagraph} style={{direction: 'rtl', marginTop: '15px'}}>
                Ease of creating an account in the application to take advantage of the exclusive offers available.            
                </p>
                </div>
                
              </Card>
            </Col>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-up"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-home"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

            <img src ={direct_call}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '43%'}}
                alt = "video_to_audio" />



                <p className={Classes.antCardParagraph} style={{marginTop: '15px'}}>
                Direct and instant communication with certified sign interpreters anytime and anywhere.                </p>
              </Card>
            </Col>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fas fa-video"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}
            <img src ={conference_call}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '43%'}}
                alt = "video_to_audio" />



                <p className={Classes.antCardParagraph} style={{marginTop: '15px'}}>
                Conference call between a sign language speaker and another applicant with spoken language,
between them a sign language interpreter.
                </p>
              </Card>
            </Col>
            

            <Col
                className={Classes.antcard}
                
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false} style={{padding: '10px !important'}}>
                {/* <i
                    className="fa fa-globe"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

                
            <img src ={call_interpreator}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '43%'}}
                alt = "call_interpreator" />

                <p className={Classes.antCardParagraph} style={{marginTop: '0px'}}>
                Contact MetaSign partners any time to take advantage of the offers in the application or to
answer any questions.                </p>
              </Card>
            </Col>
            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-globe"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

                
            <img src ={video_to_audio}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top: '25px',
                  right: '45%'}}
                alt = "video_to_audio" />

                <p className={Classes.antCardParagraph} style={{marginTop: '0px'}}>
                Convert sign language videos into voice messages - and vice versa - where you can share them
with your loved ones in any social media application.                </p>
              </Card>
            </Col>

          

            <Col
                className={Classes.antcard}
                xs={11}
                sm={11}
                md={11}
                lg={7}
                data-aos="fade-down"
                data-aos-delay="40"
                data-aos-duration="2000"
                data-aos-easing="ease-in-out-cubic"
            >
              <Card bordered={false}>
                {/* <i
                    className="fa fa-globe"
                    style={{ fontSize: "25px", color: "#0088CC", position: 'fixed', top: '25px', right: '43%'}}
                ></i> */}

                
            <img src ={security}
            className={'feature-image'}
                style={{  
                  height: '2rem',
                  position: 'fixed',
                  top:'25px',
                  right: '43%'
                }}
                alt = "security" />

                <p className={Classes.antCardParagraph} style={{marginTop: '0px'}}>
                Privacy and security of your information and personal data                </p>
              </Card>
            </Col>


          </Row>
          
        </div>
      </div>
      
  );
};

export default Feature;
