const Telephone = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5625 20.0625L20.4609 16.1172C20.5391 15.8307 20.5 15.5573 20.3438 15.2969C20.2135 15.0365 20.0182 14.8411 19.7578 14.7109L15.5391 12.9141C15.2786 12.8099 15.0182 12.7839 14.7578 12.8359C14.4974 12.9141 14.276 13.0573 14.0938 13.2656L12.375 15.375C11.0208 14.6979 9.8099 13.8255 8.74219 12.7578C7.67448 11.6901 6.80208 10.4792 6.125 9.125L8.23438 7.40625C8.44271 7.22396 8.57292 7.0026 8.625 6.74219C8.70312 6.48177 8.6901 6.22135 8.58594 5.96094L6.78906 1.74219C6.65885 1.48177 6.46354 1.27344 6.20312 1.11719C5.94271 0.986979 5.66927 0.960937 5.38281 1.03906L1.4375 1.9375C1.17708 2.01562 0.955729 2.15885 0.773438 2.36719C0.591146 2.60156 0.5 2.86198 0.5 3.14844C0.5 6.3776 1.30729 9.3724 2.92188 12.1328C4.51042 14.8411 6.64583 16.9766 9.32812 18.5391C12.1146 20.1797 15.1354 21 18.3906 21C18.651 21 18.8854 20.9089 19.0938 20.7266C19.3281 20.5443 19.4844 20.3229 19.5625 20.0625ZM1.75 3.14844L5.65625 2.25L7.45312 6.46875L4.60156 8.77344C5.53906 10.8047 6.61979 12.4323 7.84375 13.6562C9.06771 14.8802 10.6953 15.9609 12.7266 16.8984L15.0312 14.0469L19.25 15.8438L18.3516 19.75C15.3568 19.75 12.5703 18.9948 9.99219 17.4844C7.46615 16 5.47396 14.0078 4.01562 11.5078C2.50521 8.92969 1.75 6.14323 1.75 3.14844Z"
        fill="#0088CC"
      />
    </svg>
  );
};
export default Telephone;
