import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import i18n from "i18next";
import { Col, Row } from "antd";
import Links from "antd/lib/typography/Link";
import Classes from "./footer.module.css";
import { Link } from "react-router-dom";
import AppleIcon from "./AppleIcon";
import GoogleIcon from "./GoogleIcon";

const Footer = () => {
  return i18n.language === "ar" ? (
    <div className={`${Classes.FooterContainer} font`} id="footer">
      <div className="App-Container">
        <Row className={`${Classes.FooterSection} font`}>
          <Col className={Classes.FooterCol} lg={7} sm={24} xs={24} md={24}>
            <Row>
              <p className={Classes.ColHeader}>انضم لنا</p>
            </Row>
            <Row className={Classes.IconRow}>
              <LinkedinOutlined />
              <InstagramOutlined />
              <FacebookOutlined />
            </Row>
            <Row style={{ justifyContent: "space-around" }}>
              <p className={Classes.p}> حمل التطبيق </p>
              <div
                className={Classes.feedback}
                style={{
                  flexDirection: "column",
                }}
              >
                <div className={Classes["landing-page"]}>
                  <AppleIcon />
                  <a
                    href={
                      "https://apps.apple.com/us/app/metasign-app/id1642515920"
                    }
                  >
                    للآيفون
                  </a>
                </div>
                <div className={Classes["landing-page"]}>
                  <GoogleIcon />
                  <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.itland.metasign"
                    }
                  >
                    للأندرويد
                  </a>
                </div>
              </div>
            </Row>
          </Col>
          <Col lg={7} sm={24} xs={24} md={24} className={Classes.FooterCol}>
            <Row>
              <p className={Classes.ColHeader}>المحتوى</p>
            </Row>
            <Row className={Classes.FotColNavbar}>
              <Links href="#howWork" className={Classes.anttypography}>
                <p>الرئيسية</p>
              </Links>

              <Links href="#features" className={Classes.anttypography}>
                <p>ميزات النظام</p>
              </Links>

              <Links href="#whoWeAre" className={Classes.anttypography}>
                <p>من نحن</p>
              </Links>

              <Links href="#howWork" className={Classes.anttypography}>
                <p>حول التطبيق</p>
              </Links>

              <Links href="#Subscription" className={Classes.anttypography}>
                <p>الاشتراكات</p>
              </Links>

              <Links href="#contact" className={Classes.anttypography}>
                <p>تواصل معنا</p>
              </Links>
            </Row>
          </Col>
          <Col lg={7} sm={24} xs={24} md={24} className={Classes.FooterCol}>
            <Row>
              <p className={Classes.ColHeader}>MetaSign</p>
            </Row>
            <Row
              className={Classes.FirstColParagraph}
              style={{ fontFamily: "Cairo" }}
            >
              <p style={{ fontFamily: "Cairo", direction: "rtl" }}>
                MetaSign الأول في مجال التواصل
              </p>
              <p style={{ fontFamily: "Cairo" }}>
                يعمل على خلق تواصل بين جميع أفراد المجتمع
              </p>
            </Row>
          </Col>
        </Row>
        <Row className={Classes.SecondFooterRow}>
          <Col
            lg={7}
            sm={12}
            xs={12}
            md={12}
            className={Classes.SecondFooterRowCol}
            style={{ fontFamily: "Cairo" }}
          >
            <p>ITland-Technologies طّور من قبل </p>
          </Col>
          <Col
            lg={7}
            sm={0}
            xs={0}
            md={0}
            className={Classes.SecondFooterRowCol}
          ></Col>
          <Col
            lg={7}
            sm={12}
            xs={12}
            md={12}
            className={Classes.SecondFooterRowCol}
            style={{ fontFamily: "Cairo" }}
          >
            <Link to="/privacypolicy">سياسة الخصوصية</Link>
            <Link to="/termsandconditions" style={{ marginLeft: "30px" }}>
              الشروط والأحكام{" "}
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <div className={`${Classes.FooterContainer} font`} id="footer">
      <div className="App-Container">
        <Row className={`${Classes.FooterSection} font`}>
          <Col className={Classes.FooterCol} lg={7} sm={24} xs={24} md={24}>
            <Row>
              <p className={Classes.ColHeader}> Join Us</p>
            </Row>
            <Row className={Classes.IconRow}>
              <LinkedinOutlined />
              <InstagramOutlined />
              <FacebookOutlined />
            </Row>
            <Row style={{ justifyContent: "space-around" }}>
              <p className={Classes.p}> Download App </p>
              <div
                className={Classes.feedback}
                style={{
                  flexDirection: "column",
                }}
              >
                <div className={Classes["landing-page"]}>
                  <AppleIcon />
                  <a
                    href={
                      "https://apps.apple.com/us/app/metasign-app/id1642515920"
                    }
                  >
                    For Iphone
                  </a>
                </div>
                <div className={Classes["landing-page"]}>
                  <GoogleIcon />
                  <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.itland.metasign"
                    }
                  >
                    For Android
                  </a>
                </div>
              </div>
            </Row>
          </Col>
          <Col lg={7} sm={24} xs={24} md={24} className={Classes.FooterCol}>
            <Row>
              <p className={Classes.ColHeader}>Content</p>
            </Row>
            <Row className={Classes.FotColNavbar}>
              <Links href="#howWork" className={Classes.anttypography}>
                <p>Main</p>
              </Links>

              <Links href="#features" className={Classes.anttypography}>
                <p> Features</p>
              </Links>

              <Links href="#whoWeAre" className={Classes.anttypography}>
                <p>Who Are we </p>
              </Links>

              <Links href="#howWork" className={Classes.anttypography}>
                <p> About App</p>
              </Links>

              <Links href="#Subscription" className={Classes.anttypography}>
                <p>Subscriptions</p>
              </Links>

              <Links href="#contact" className={Classes.anttypography}>
                <p> Contact Us</p>
              </Links>
            </Row>
          </Col>
          <Col lg={7} sm={24} xs={24} md={24} className={Classes.FooterCol}>
            <Row>
              <p className={Classes.ColHeader}>MetaSign</p>
            </Row>
            <Row
              className={Classes.FirstColParagraph}
              style={{ fontFamily: "Cairo" }}
            >
              <p style={{ fontFamily: "Cairo" }}>
                {" "}
                MetaSign first in communication domain.{" "}
              </p>
              <p style={{ fontFamily: "Cairo" }}>
                {" "}
                Make commnication between all people in community.
              </p>
            </Row>
          </Col>
        </Row>
        <Row className={Classes.SecondFooterRow}>
          <Col
            lg={7}
            sm={12}
            xs={12}
            md={12}
            className={Classes.SecondFooterRowCol}
            style={{ fontFamily: "Cairo" }}
          >
            <p> Developed By ITland-Technologies</p>
          </Col>
          <Col
            lg={7}
            sm={0}
            xs={0}
            md={0}
            className={Classes.SecondFooterRowCol}
          ></Col>
          <Col
            lg={7}
            sm={12}
            xs={12}
            md={12}
            className={Classes.SecondFooterRowCol}
            style={{ fontFamily: "Cairo" }}
          >
            <Link to="/privacypolicy"> Privacy Policy</Link>
            <Link to="/termsandconditions" style={{ marginLeft: "30px" }}>
              Terms And Conditions
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
