import { Row, Col,Image, Slider } from "antd";
import SubscriptionsImage from "../icons/SubscriptionsImage";
import Classes from "./subscription.module.css";
import i18n  from "i18next";

const Subscription = () => {
  return( 
    i18n.language === 'ar'?  
      <div className="App-Container" id="Subscription">
        <div
          className={`${Classes.SubscriptionSection} `}
          
        >
          <div className={Classes.HeaderSectionParagraph}>
            <p style={{fontSize: '32px'}}>
              الاشتراكات
            </p>
          </div>
          <Row className={Classes.HeaderSectionContainer}>
            <Col 
              sm={24}
              xs={24}
              md={24}
              lg={12}
              className={Classes.HeaderSectionContent}
              data-aos="fade-right"
              data-aos-delay="40"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              style={{ overflow: "hidden" }}
            >
              {" "}
                  <p style={{direction: 'rtl',  textAlign: 'justify', fontFamily: 'Cairo', padding: '10px'}}>نطمح في MetaSign إلى تأمين تواصل لجميع أفراد مجتمع الصم مع مقدمي الخدمات من وزارات، مؤسسات خاصة وحكومية، بنوك، شركات كبرى، سفارات، جمعيات تعاونية وغيرها.</p>

            </Col>
            <Col
              lg={12}
              sm={24}
              xs={24}
              md={24}
              data-aos="fade-up"
              data-aos-delay="40"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-cubic"
              className="image-about"
            >
              <div className={Classes.ImageContainer}>
              <SubscriptionsImage></SubscriptionsImage>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      :

      <div className="App-Container" id="Subscription">
        <div
          className={`${Classes.SubscriptionSection} `}
          
        >
          <div className={Classes.HeaderSectionParagraph}>
            <p style={{fontSize: '32px'}}>
            Subscriptions
            </p>
          </div>
          <Row className={Classes.HeaderSectionContainer}>
            <Col 
              sm={24}
              xs={24}
              md={24}
              lg={12}
              className={Classes.HeaderSectionContent}
              data-aos="fade-right"
              data-aos-delay="40"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              style={{ overflow: "hidden" }}
            >
              {" "}
                  <p style={{textAlign: 'justify', fontFamily: 'Cairo', padding: '10px'}}>
                  At MetaSign, we aspire to provide communication for all members of the deaf community with service
providers from ministries, private and governmental institutions, banks, major companies, embassies,
cooperative societies, and others.
                    </p>


            </Col>
            <Col
              lg={12}
              sm={24}
              xs={24}
              md={24}
              data-aos="fade-up"
              data-aos-delay="40"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-cubic"
              className="image-about"
            >
              <div className={Classes.ImageContainer}>
              <SubscriptionsImage></SubscriptionsImage>
              </div>
            </Col>
          </Row>
        </div>
      </div>

    );
  };
  
  export default Subscription;
  