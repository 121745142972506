import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TabletOutlined, 
} from "@ant-design/icons";
import { Col, Row,Image } from "antd";
import { Button } from "antd/lib/radio";
import Link from "antd/lib/typography/Link";
import Logo from "../icons/Logo";
import Classes from "./homeSection.module.css";
import i18n  from "i18next";

const HomeSection = () => {
  return( 
    i18n.language === 'ar'?  
  
    <>
    <div id="Home">
      <Row className={Classes.homeSection}>
        <Col className={Classes.homeSectionRow}>

        <Logo height={150} width={200} />
      
        </Col>
        <Col className={Classes.homeSectionRow}>
          <p className={`${Classes.homeSectionRowParagraph} font`}>
              MetaSign
          </p>
        </Col>
        <Col
          className={`${Classes.homeSectionRow} ${Classes.homeSectionRowTextDev} font ` }
          style = {{padding: '20px'}}
        >
          <p className={`${Classes.homeSectionRowText} font`} style={{direction: 'rtl'}}>
           تطبيق مميز يقدم خدمة الترجمة الفورية بلغة الإشارة لذوي الإعاقة السمعية.
          </p>
          <p className={`${Classes.homeSectionRowText} font`} style={{direction: 'rtl'}}>
          نسعى لتقديم أفضل طرق التواصل بين جميع أفراد المجتمع.
          </p>
        </Col>
        <Col
          className={`${Classes.homeSectionRow} ${Classes.homeSectionRowIcon}`}
        >
          <LinkedinOutlined />
          <InstagramOutlined />
          <FacebookOutlined />
        </Col>
        <Col className={Classes.homeSectionRow}>
          <Button className={`${Classes.homeSectionRowButton} font`}>
            <Link href="#features" className={Classes.anttypography}>
            المزيد
            </Link>
            
          </Button>
        </Col>
      </Row>
      </div>
    </> 

    :
    <>
    <div id="Home">
      <Row className={Classes.homeSection}>
        <Col className={Classes.homeSectionRow}>

        <Logo height={150} width={200} />
      
        </Col>
        <Col className={Classes.homeSectionRow}>
          <p className={`${Classes.homeSectionRowParagraph} font`}>
              MetaSign
          </p>
        </Col>
        <Col
          className={`${Classes.homeSectionRow} ${Classes.homeSectionRowTextDev} font ` }
          style = {{padding: '20px'}}
        >
          <p className={`${Classes.homeSectionRowText} font`} style = {{fontSize: '15px'}}>
          A distinctive application that provides instant interpretation service in sign language for people with
hearing disabilities.          </p>
          <p className={`${Classes.homeSectionRowText} font`} style = {{fontSize: '15px'}}>
          We strive to provide the best ways of communication between all members of society.
                    </p>
        </Col>
        <Col
          className={`${Classes.homeSectionRow} ${Classes.homeSectionRowIcon}`}
        >
          <LinkedinOutlined />
          <InstagramOutlined />
          <FacebookOutlined />
        </Col>
        <Col className={Classes.homeSectionRow}>
          <Button className={`${Classes.homeSectionRowButton} font`}>
            <Link href="#features" className={Classes.anttypography}>
            More
            </Link>
            
          </Button>
        </Col>
      </Row>
      </div>
    </> 
  );
};

export default HomeSection;
