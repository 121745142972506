import { Col, Row, Form, Input, Button, Image, notification } from "antd";
import i18n from "i18next";
import Location from "../icons/Location";
import Telephone from "../icons/Telephone";
import Letter from "../icons/letter";
import Classes from "./contactus.module.css";
import { useQuery, useMutation } from "@tanstack/react-query";
import { PostContact } from "../react-query-services/Contact";
import Map from "./Map";
import { useState } from "react";
const { TextArea } = Input;

const ContactUs = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const sendMessageToContact = useMutation(PostContact, {
    onSuccess: (res: any) => {
      form.resetFields();
      notification.open({
        message: "Your Message Sent Successfully",
      });
    },
    onError: (res: any) => {
      notification.open({
        message: "Your Message Failed To Send",
      });
    },
  });

  const onFinishHandler = (e: any) => {
    setLoading(true);
    let body = {
      name: e.name,
      emailOrPhoneNumber: e.emailOrPhoneNumber,
      message: e.message,
    };
    sendMessageToContact.mutateAsync(body);
  };

  return i18n.language === "ar" ? (
    <div className="App-Container">
      <div className={Classes.ContactUsSection} id="contact">
        <div className={Classes.HeaderSectionParagraph}>
          <p style={{ fontSize: "32px" }}>تواصل معنا</p>
        </div>
      </div>
      <Row className={Classes.HeaderSectionContainer}>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={9}
          className="contact-left-contnet"
          data-aos="fade-right"
          data-aos-delay="0"
          data-aos-duration="800"
          data-aos-easing="ease-in-out-cubic"
        >
          <Row className={Classes.Information}>
            <Row
              justify="end"
              style={{
                gap: 10,

                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo" }}
              >
                مدينة الكويت - برج التجارية
              </p>
              {/* <HomeOutlined className="contact-left-content-icon"></HomeOutlined> */}
              <Location></Location>
            </Row>
            <Row
              justify="end"
              style={{
                gap: 5,
                alignItems: "center",
                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo", marginRight: 5 }}
              >
                من 9 صباحاًً إلى 5 مساءً
              </p>

              <i
                className="far fa-clock fa-fw"
                style={{ fontSize: 18, color: "#0088CC" }}
              ></i>
            </Row>
            <Row
              justify="end"
              style={{
                gap: 10,
                alignItems: "center",
                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo", direction: "rtl" }}
              >
                هاتف 96555077018+
              </p>
              <Telephone></Telephone>
            </Row>
            <Row
              justify="end"
              style={{
                gap: 10,
                alignItems: "center",
                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo" }}
              >
                info@metasignapp.com
              </p>
              <Letter></Letter>
            </Row>
          </Row>
          <Row className={Classes.MapRow}>
            <Map></Map>
          </Row>
        </Col>

        <Col
          className={Classes.FormSectionContent}
          xs={24}
          sm={24}
          md={13}
          lg={14}
          // data-aos="fade-up"
          // data-aos-delay="0"
          // data-aos-duration="800"
          // data-aos-easing="ease-in-out-cubic"
        >
          <Form onFinish={onFinishHandler} form={form}>
            {/* <Form onFinish={finish} form={form}> */}
            <Form.Item
              style={{ marginBottom: 36 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "حقل الاسم المطلوب",
                },
              ]}
            >
              <Input placeholder="الاسم" style={{ direction: "rtl" }}></Input>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 36 }}
              name="emailOrPhoneNumber"
              rules={[
                {
                  required: true,
                  message: "حقل الهاتف أو البريد الإلكتروني  مطلوب",
                },
              ]}
            >
              <Input
                placeholder="رقم الهاتف أو البريد الإلكتروني"
                style={{ direction: "rtl" }}
              ></Input>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 36 }}
              name="message"
              rules={[
                {
                  required: true,
                  message: "حقل الرسالة مطلوب ",
                },
              ]}
            >
              <TextArea
                placeholder="رسالتك"
                style={{ direction: "rtl", minHeight: 220 }}
              ></TextArea>
            </Form.Item>
            <Form.Item style={{ marginBottom: 36 }}>
              <Button
                className={Classes.ContactFormButton}
                htmlType="submit"
                loading={loading}
              >
                {" "}
                إرسال
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  ) : (
    <div className="App-Container">
      <div className={Classes.ContactUsSection} id="contact">
        <div className={Classes.HeaderSectionParagraph}>
          <p style={{ fontSize: "32px" }}>Contact Us</p>
        </div>
      </div>
      <Row className={Classes.HeaderSectionContainer}>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={9}
          className="contact-left-contnet"
          data-aos="fade-right"
          data-aos-delay="0"
          data-aos-duration="800"
          data-aos-easing="ease-in-out-cubic"
        >
          <Row className={Classes.Information}>
            <Row
              justify="start"
              style={{
                gap: 10,

                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <Location></Location>
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo" }}
              >
                Kuwait City - Al-Tijaria Tower
              </p>
              {/* <HomeOutlined className="contact-left-content-icon"></HomeOutlined> */}
            </Row>
            <Row
              justify="start"
              style={{
                gap: 5,
                alignItems: "center",
                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <i
                className="far fa-clock fa-fw"
                style={{ fontSize: 18, color: "#0088CC" }}
              ></i>

              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo", marginRight: 5 }}
              >
                From 9 am to 5 pm
              </p>
            </Row>
            <Row
              justify="start"
              style={{
                gap: 10,
                alignItems: "center",
                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <Telephone></Telephone>
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo", direction: "ltr" }}
              >
                + 96555077018
              </p>
            </Row>
            <Row
              justify="start"
              style={{
                gap: 10,
                alignItems: "center",
                marginBottom: 20,
                width: "100%",
              }}
              className={Classes.Content}
            >
              <Letter></Letter>
              <p
                className="contact-left-content-p"
                style={{ fontFamily: "Cairo" }}
              >
                info@metasignapp.com
              </p>
            </Row>
          </Row>
          <Row className={Classes.MapRow}>
            <Map></Map>
          </Row>
        </Col>

        <Col
          className={Classes.FormSectionContent}
          xs={24}
          sm={24}
          md={13}
          lg={14}
          // data-aos="fade-up"
          // data-aos-delay="0"
          // data-aos-duration="800"
          // data-aos-easing="ease-in-out-cubic"
        >
          <Form onFinish={onFinishHandler} form={form}>
            {/* <Form onFinish={finish} form={form}> */}
            <Form.Item
              style={{ marginBottom: 36 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input placeholder="Name" style={{ direction: "ltr" }}></Input>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 36 }}
              name="emailOrPhoneNumber"
              rules={[
                {
                  required: true,
                  message: "Phone or Email is required",
                },
              ]}
            >
              <Input
                placeholder="Phone or Email"
                style={{ direction: "ltr" }}
              ></Input>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 36 }}
              name="message"
              rules={[
                {
                  required: true,
                  message: "Message Is Required",
                },
              ]}
            >
              <TextArea
                placeholder="Message"
                style={{ direction: "ltr", minHeight: 220 }}
              ></TextArea>
            </Form.Item>
            <Form.Item style={{ marginBottom: 36 }}>
              <Button
                className={Classes.ContactFormButton}
                htmlType="submit"
                loading={loading}
              >
                {" "}
                Send
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       height: 250,
  //       alignItems: "center",
  //       width: "100%",
  //       justifyContent: "center",
  //     }}
  //   >
  //     <div style={{ position: "relative" }}>
  //       <Map></Map>
  //     </div>
  //   </div>
  // );
};

export default ContactUs;
