import Classes from "./privacypolicy.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetPrivacy } from "../react-query-services/PrivacyPolicy";

const PrivacyPolicy = () => {
  const { isLoading, isError, error, data, isFetching, refetch } = useQuery({
    queryKey: ["GetPrivacy"],
    queryFn: GetPrivacy,
  });

  let lang = localStorage.getItem("current_language");

  return (
    <div className="App-Container">
      {lang === "en" ? (
        <div
          dangerouslySetInnerHTML={{ __html: data?.result?.enPrivacyPolicy }}
        />
      ) : (
        <div
          style={{ textAlign: "right" }}
          dangerouslySetInnerHTML={{ __html: data?.result?.arPrivacyPolicy }}
        />
      )}
    </div>
  );
};

export default PrivacyPolicy;
