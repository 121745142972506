import Classes from "./privacypolicy.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetTerms } from "../react-query-services/TermsAndConditions";

const TermsAndConditions = () => {
  const { isLoading, isError, error, data, isFetching, refetch } = useQuery({
    queryKey: ["GetPrivacy"],
    queryFn: GetTerms,
  });

  let lang = localStorage.getItem("current_language");

  return (
    <div className="App-Container">
      {lang === "en" ? (
        <div
          dangerouslySetInnerHTML={{
            __html: data?.result?.enTermsAndConditions,
          }}
        />
      ) : (
        <div
          style={{ textAlign: "right" }}
          dangerouslySetInnerHTML={{
            __html: data?.result?.arTermsAndConditions,
          }}
        />
      )}
    </div>
  );
};

export default TermsAndConditions;
