const Letter = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 0L1.875 0C1.35417 0 0.911458 0.182292 0.546875 0.546875C0.182292 0.911458 0 1.35417 0 1.875L0 13.125C0 13.6458 0.182292 14.0885 0.546875 14.4531C0.911458 14.8177 1.35417 15 1.875 15H18.125C18.6458 15 19.0885 14.8177 19.4531 14.4531C19.8177 14.0885 20 13.6458 20 13.125V1.875C20 1.35417 19.8177 0.911458 19.4531 0.546875C19.0885 0.182292 18.6458 0 18.125 0ZM1.875 1.25L18.125 1.25C18.3073 1.25 18.4505 1.3151 18.5547 1.44531C18.6849 1.54948 18.75 1.69271 18.75 1.875V3.47656C17.8385 4.25781 15.8724 5.84635 12.8516 8.24219L12.4609 8.55469C11.9401 8.9974 11.5365 9.3099 11.25 9.49219C10.7552 9.83073 10.3385 10 10 10C9.66146 10 9.24479 9.83073 8.75 9.49219C8.4375 9.3099 8.02083 8.9974 7.5 8.55469L7.14844 8.24219C4.1276 5.84635 2.16146 4.25781 1.25 3.47656V1.875C1.25 1.69271 1.30208 1.54948 1.40625 1.44531C1.53646 1.3151 1.69271 1.25 1.875 1.25ZM18.125 13.75H1.875C1.69271 13.75 1.53646 13.6979 1.40625 13.5938C1.30208 13.4635 1.25 13.3073 1.25 13.125L1.25 5.11719C2.16146 5.84635 3.86719 7.21354 6.36719 9.21875L6.75781 9.53125C7.35677 10.0521 7.85156 10.4297 8.24219 10.6641C8.89323 11.0547 9.47917 11.25 10 11.25C10.5208 11.25 11.1068 11.0547 11.7578 10.6641C12.1224 10.4297 12.6172 10.0521 13.2422 9.53125L13.6328 9.21875C16.1328 7.21354 17.8385 5.84635 18.75 5.11719V13.125C18.75 13.3073 18.6849 13.4635 18.5547 13.5938C18.4505 13.6979 18.3073 13.75 18.125 13.75Z"
        fill="#0088CC"
      />
    </svg>
  );
};
export default Letter;
