import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";

class TermsAndConditionsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    console.log(process.env);

    super({
      baseURL: `https://api.metasignapp.com/api/`,
      ...config,
    });
  }

  public GetTerms = async (): Promise<any> => {
    return this.get("/services/app/Configuration/GetTermsAndConditions");
  };
}

export const TermsService = new TermsAndConditionsService();
