
import vision from '../icons/vision.png';

const OurVisionImage = () => {
  return (
    <img src={vision}
    style = {{
      width: '100%',
      borderRadius: '30px'
    }}
    />
  );
};

export default OurVisionImage;
