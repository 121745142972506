import { MapContainer, Popup, TileLayer, Marker } from "react-leaflet";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import L from "leaflet";

const Markers = L.icon({
  iconUrl: "../assets/free.png",
  iconSize: [38, 38],
});
const Map = () => {
  let position = { lat: 29.37053, lng: 47.99278 };
  return (
    <MapContainer
      center={position}
      zoom={20}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Marker position={position} icon={Markers}></Marker>
    </MapContainer>
  );
};

export default Map;
