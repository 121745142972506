import { Col, Row, Image, Button } from "antd";
import Classes from "./howWorkSection.module.css";
import offers from '../icons/offers.png';
import easylogin from '../icons/easylogin.png';
import aboutapp from '../icons/aboutapp.png';
import userinterface from '../icons/userinterface.png';
import i18n  from "i18next";

const HowWorkSection = () => {
  return( 
    i18n.language === 'ar'?  
    <div className="App-Container" id="howWork">
      <div className={Classes.HeaderSectionParagraph}>
      <p style={{fontSize: '32px'}}>
              حول التطبيق
            </p>
          </div>
      <Row
        className={Classes.HowWorkSectionRowFirst}
      >
        
        <Row className={Classes.HowWorkSectionRowOdd}>
          <Col
            lg={12}
            sm={24}
            xs={24}
            md={24}
            data-aos="fade-down"
            data-aos-delay="40"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out-cubic"
            className={Classes.HowWorKSectionImage}
          >
            <Image src={aboutapp} preview={false} width={428} height={461}></Image>
          </Col>
          <Col 
           lg={12}
           sm={24}
           xs={24}
           md={24}
          className={`${Classes.HowWorkSectionRowParagraph} font`}
          data-aos="fade-up"
          data-aos-delay="40"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          
          >
            <p className={Classes.HowWorkSectionRowParagraphHeader}>MetaSign</p>
              <p className={Classes.HowWorkSectionRowParagraph}></p>
              <p style={{direction:'rtl', textAlign:'justify', padding: '10px'}}>
                  تقوم شركة MetaSign بتمثيل التطبيق رسمياً في دولة الكويت، وتعتبر من الشركات الرائدة إقليمياً في توفير خدمات التواصل المرئي لذوي الإعاقة السمعية بوساطة مترجمين فوريين حيث يتمتع الطاقم الفني للشركة بالخبرة والرؤية والقدرة على تتبع متطلبات الأشخاص ذوي الإعاقة السمعية.
              </p>
              <p style={{direction:'rtl', textAlign:'justify', marginTop: '13 px', padding: '10px'}}>
                  ويعتبر التطبيق الكويتي الأول من نوعه، سهل الاستعمال يمكن تحميله على نظام ال IOS والأندرويد يعمل على ربط أصحاب المشاكل في السمع أو الكلام عن طريق مترجمي لغة إشارة من الكويت لتسهيل حياتهم ودمجهم في المجتمع بسلاسة وسهولة.
              </p>
          </Col>
        </Row>

        <Row className={Classes.HowWorkSectionRow}>
        <Col lg={12}
         sm={24}
         xs={24}
         md={24}
        className={`${Classes.HowWorkSectionRowParagraph} font`}
         data-aos="fade-down"
         data-aos-delay="40"
         data-aos-duration="2000"
         data-aos-easing="ease-in-out-cubic"
        
        >
            <p className={Classes.HowWorkSectionRowParagraphHeader}>واجهة المستخدم</p>
            <p className={Classes.HowWorkSectionRowParagraph}>
            <p style={{direction:'rtl', textAlign:'justify', padding: '10px'}}> واجهة تجمع عالم لغة الإشارة بعالم لغة الكلام بما فيه من تكنولوجيا ومميزات تدمج من لديهم صعوبات في السمع أو الكلام من طريق مترجمينا المعتمدين وشركائنا الذين يقدمون خدماتهم وعروضهم لهذه الفئة.
            </p>
            </p>

          </Col>
          <Col
            lg={12}
            sm={24}
            xs={24}
            md={24}
            data-aos="fade-up"
            data-aos-delay="40"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out-cubic"
            className={Classes.HowWorKSectionImage}
          >
            {" "}
            <Image src={userinterface} preview={false} width={428} height={461}></Image>
          </Col>
        </Row>

        <Row className={Classes.HowWorkSectionRowOdd}>
          <Col
            sm={24}
            xs={24}
            md={24}
            lg={12}
            data-aos="fade-down"
            data-aos-delay="40"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out-cubic"
            className={Classes.HowWorKSectionImage}
          >
            {" "}
            <Image src={offers} preview={false} width={428} height={461}></Image>
          </Col>
          <Col
           lg={12}
           sm={24}
           xs={24}
           md={24}
           className={`${Classes.HowWorkSectionRowParagraph} font`}
           data-aos="fade-up"
           data-aos-delay="40"
           data-aos-duration="2000"
           data-aos-easing="ease-in-out-cubic"
          >
            <p className={Classes.HowWorkSectionRowParagraphHeader}> الباقات والعروض </p>
            <p className={Classes.HowWorkSectionRowParagraph}>
            <p style={{direction:'rtl', textAlign:'justify', padding: '10px'}}> اختر ما يناسبك من عروض وباقات دقائق للاتصال أو رسائل للتواصل مع مترجمينا وشركائنا وجهات الاتصال الخاصة بك في أي وقت.</p>
            </p>
           

          </Col>
        </Row>

        <Row className={Classes.HowWorkSectionRow}>
        <Col 
         lg={12}
         sm={24}
         xs={24}
         md={24}
         className={`${Classes.HowWorkSectionRowParagraph} font`}
         data-aos="fade-down"
         data-aos-delay="40"
         data-aos-duration="2000"
         data-aos-easing="ease-in-out-cubic"
        >
            <p className={Classes.HowWorkSectionRowParagraphHeader}>سهولة التسجيل</p>
            <p className={Classes.HowWorkSectionRowParagraph}>
            <p style={{direction:'rtl', textAlign:'justify', padding: '10px'}}>يمكن للعميل تسجيل الدخول إلى التطبيق عن طريق رقم الموبايل.  ويمكن للمترجم أيضاً تسجيل الدخول إلى الحساب الذي تنشئه إدارة التطبيق برقم هاتفه بعد اجتياز الاختبارات اللازمة واعتماده كمترجم لغة إشارة لدى MetaSign، كما يمكنه العمل في أي وقت وفي أي مكان عن طريق رقم هاتفه.</p>
            </p>
           
          </Col>
          <Col
            lg={12}
            sm={24}
            xs={24}
            md={24}
            data-aos="fade-up"
            data-aos-delay="40"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out-cubic"
            className={Classes.HowWorKSectionImage}
          >
            {" "}
            <Image src={easylogin} preview={false} width={428} height={461}></Image>
          </Col>
        </Row>
      </Row>
    </div>
    
    : 


    <div className="App-Container" id="howWork">
    <div className={Classes.HeaderSectionParagraph}>
    <p style={{fontSize: '32px'}}>
    About the App 
          </p>
        </div>
    <Row
      className={Classes.HowWorkSectionRowFirst}
    >
      
      <Row className={Classes.HowWorkSectionRowOdd}>
        <Col
          lg={12}
          sm={24}
          xs={24}
          md={24}
          data-aos="fade-down"
          data-aos-delay="40"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          className={Classes.HowWorKSectionImage}
        >
          <Image src={aboutapp} preview={false} width={428} height={461}></Image>
        </Col>
        <Col 
         lg={12}
         sm={24}
         xs={24}
         md={24}
        className={`${Classes.HowWorkSectionRowParagraph} font`}
        data-aos="fade-up"
        data-aos-delay="40"
        data-aos-duration="2000"
        data-aos-easing="ease-in-out-cubic"
        
        >
          <p className={Classes.HowWorkSectionRowParagraphHeader}>MetaSign</p>
            <p className={Classes.HowWorkSectionRowParagraph}></p>
            <p style={{ textAlign:'justify', padding: '10px'}}>
            MetaSign officially represents the application in the State of Kuwait, and it is considered one of the
regional leaders in providing visual communication services for people with hearing disabilities through
interpreters, where the company's technical staff has the experience, vision, and ability to track the
requirements of people with hearing disabilities.            </p>
            <p style={{ textAlign:'justify', marginTop: '13 px', padding: '10px'}}>
            The Kuwaiti application is the first of its kind, easy to use and can be downloaded on the IOS and Android
system. It connects people with hearing or speech disabilities through sign language interpreters from
Kuwait to facilitate their lives and integrate them into society smoothly and easily.        
    </p>
        </Col>
      </Row>

      <Row className={Classes.HowWorkSectionRow}>
      <Col lg={12}
       sm={24}
       xs={24}
       md={24}
      className={`${Classes.HowWorkSectionRowParagraph} font`}
       data-aos="fade-down"
       data-aos-delay="40"
       data-aos-duration="2000"
       data-aos-easing="ease-in-out-cubic"
      
      >
          <p className={Classes.HowWorkSectionRowParagraphHeader}>User Interface</p>
          <p className={Classes.HowWorkSectionRowParagraph}>
          <p style={{ textAlign:'justify', padding: '10px'}}> 
          An interface that combines the world of sign language with the world of speech language with its
technology and features that integrate those who have hearing or speech difficulties through our certified
interpreters and partners who provide their services and offers for this category.
          </p>
          </p>

        </Col>
        <Col
          lg={12}
          sm={24}
          xs={24}
          md={24}
          data-aos="fade-up"
          data-aos-delay="40"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          className={Classes.HowWorKSectionImage}
        >
          {" "}
          <Image src={userinterface} preview={false} width={428} height={461}></Image>
        </Col>
      </Row>

      <Row className={Classes.HowWorkSectionRowOdd}>
        <Col
          sm={24}
          xs={24}
          md={24}
          lg={12}
          data-aos="fade-down"
          data-aos-delay="40"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          className={Classes.HowWorKSectionImage}
        >
          {" "}
          <Image src={offers} preview={false} width={428} height={461}></Image>
        </Col>
        <Col
         lg={12}
         sm={24}
         xs={24}
         md={24}
         className={`${Classes.HowWorkSectionRowParagraph} font`}
         data-aos="fade-up"
         data-aos-delay="40"
         data-aos-duration="2000"
         data-aos-easing="ease-in-out-cubic"
        >
          <p className={Classes.HowWorkSectionRowParagraphHeader}> Packages and Offers</p>
          <p className={Classes.HowWorkSectionRowParagraph}>
          <p style={{textAlign:'justify', padding: '10px'}}> 
          Choose what suits you best from offers and packages to communicate with our translators, partners, and
your contacts at any time.
          </p>
          </p>
         

        </Col>
      </Row>

      <Row className={Classes.HowWorkSectionRow}>
      <Col 
       lg={12}
       sm={24}
       xs={24}
       md={24}
       className={`${Classes.HowWorkSectionRowParagraph} font`}
       data-aos="fade-down"
       data-aos-delay="40"
       data-aos-duration="2000"
       data-aos-easing="ease-in-out-cubic"
      >
          <p className={Classes.HowWorkSectionRowParagraphHeader}>Ease of registration </p>
            <p className={Classes.HowWorkSectionRowParagraph}>
            <p style={{ textAlign:'justify', padding: '10px'}}>
The customer can log into the application using the mobile number. The translator can also log into the
account created by the administration with his phone number after passing the necessary tests and being
certified as a sign language translator with MetaSign, and he can work anytime and anywhere using his
phone number.              </p>
            </p>
         
        </Col>
        <Col
          lg={12}
          sm={24}
          xs={24}
          md={24}
          data-aos="fade-up"
          data-aos-delay="40"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          className={Classes.HowWorKSectionImage}
        >
          {" "}
          <Image src={easylogin} preview={false} width={428} height={461}></Image>
        </Col>
      </Row>
    </Row>
  </div>
  );
};

export default HowWorkSection;
