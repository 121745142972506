const ApplePlay = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2 10.6354C14.1766 8.10807 16.3616 6.87983 16.4614 6.82201C15.2241 5.09468 13.3053 4.85871 12.6311 4.83983C11.0204 4.67701 9.45771 5.76249 8.63693 5.76249C7.80013 5.76249 6.53569 4.85517 5.17389 4.8823C3.42142 4.90826 1.78109 5.87929 0.881443 7.38835C-0.974551 10.4643 0.409433 14.9832 2.18779 17.4692C3.07758 18.688 4.11649 20.046 5.47706 19.9988C6.80805 19.9469 7.30471 19.1871 8.91176 19.1871C10.5028 19.1871 10.9699 19.9988 12.3575 19.9681C13.7859 19.9469 14.6856 18.7446 15.5433 17.5164C16.5711 16.1217 16.984 14.746 17 14.6764C16.968 14.6658 14.2283 13.6641 14.2 10.6354ZM11.5787 3.20571C12.2947 2.34912 12.784 1.18341 12.6484 0C11.6119 0.0436552 10.3167 0.685504 9.56986 1.52439C8.9093 2.26299 8.32021 3.47354 8.47179 4.61211C9.63641 4.6947 10.8318 4.04932 11.5787 3.20571Z"
        fill="white"
      />
    </svg>
  );
};

export default ApplePlay;
