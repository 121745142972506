
import goal from '../icons/goal.png';

const OurGoalImage = () => {
  return (
    <img src={goal}
    style = {{
      width: '100%',
      borderRadius: '30px'
    }}
    />
  );
};

export default OurGoalImage;
