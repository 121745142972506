import { Row, Col, Button, Image } from "antd";
import Classes from "./ourGoal.module.css";
import OurGoalImage from "../icons/OurGoalImage";
import i18n  from "i18next";

const OurGoal = () => {
  return( 
    i18n.language === 'ar'?
    <div className="App-Container" id="ourGoal">
      <div
        className={`${Classes.OurGoalSection} `}
        id="OurGoal"
      >
        <Row
        className={Classes.HeaderSectionParagraph} >
          <p style={{fontSize: '32px'}}>
            {" "}
            هدفنا
          </p>
        </Row>
        <Row className={Classes.HeaderSectionContainer}>


          <Col 
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className={Classes.HeaderSectionContent}
            data-aos="fade-right"
            data-aos-delay="40"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            style={{ overflow: "hidden" }}
          >
            {" "}
            <p className={Classes.Paragraph}  style={{direction: 'rtl', textAlign: 'justify', fontFamily: 'Cairo'}}>
              إيصال الخدمة إلى أكبر عدد ممكن من الذين يواجهون صعوبة في السمع أو الكلام لدمجهم في المجتمع بطريقة عصرية وتمكين استفادتهم من جميع الخدمات المقدمة من شركائنا وتسهيل تواصلهم مع من يحبون في أي وقت، واستخدامهم لوسائل التواصل الاجتماعي والتكنولوجيا التي وصل إليها العالم.
            </p>
          </Col>

          <Col
              sm={24}
              xs={24}
              md={24}
              lg={12}
              data-aos="fade-up"
              data-aos-delay="40"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-cubic"
              className="image-about"
          >
            <div className={Classes.ImageContainer}>
              <OurGoalImage></OurGoalImage>
            </div>
          </Col>

        </Row>
      </div>
    </div> :
    <div className="App-Container" id="ourGoal">
    <div
      className={`${Classes.OurGoalSection} `}
      id="OurGoal"
    >
      <Row
      className={Classes.HeaderSectionParagraph} >
        <p style={{fontSize: '32px'}}>
          {" "}
          Our Goals
        </p>
      </Row>
      <Row className={Classes.HeaderSectionContainer}>


        <Col 
          sm={24}
          xs={24}
          md={24}
          lg={12}
          className={Classes.HeaderSectionContent}
          data-aos="fade-right"
          data-aos-delay="40"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out-cubic"
          style={{ overflow: "hidden" }}
        >
          {" "}
          <p className={Classes.Paragraph}  style={{ textAlign: 'justify', fontFamily: 'Cairo'}}>
          Deliver the service to the largest possible number of those who have difficulty hearing or speaking, to
integrate them into society, enable them to benefit from all the services provided by our partners,
facilitate their communication with their family and friends at any time, and use social media and
technology that the world has reached
          </p>
        </Col>

        <Col
            sm={24}
            xs={24}
            md={24}
            lg={12}
            data-aos="fade-up"
            data-aos-delay="40"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out-cubic"
            className="image-about"
        >
          <div className={Classes.ImageContainer}>
            <OurGoalImage></OurGoalImage>
          </div>
        </Col>

      </Row>
    </div>
  </div>
  );
};

export default OurGoal;
