import { Row, Col, Button, Image } from "antd";
import MrsServiceImage from "../icons/MrsServiceImage";
import Classes from "./mrsService.module.css";
import './mrsService.css';
import i18n  from "i18next";
  

const MrsService = () => {
  return(
     
    i18n.language === 'ar'?  
    <div className="App-Container" id="mrsService">
      <div
        className={`${Classes.WhoAreWeSection} `}
        id="mrsService"
      >
        <Row
        className={`${Classes.HeaderSectionParagraph}` } >
          <p style={{fontSize: '32px', direction: 'rtl'}}>
            {" "}
            خدمة MRS
          </p>
        </Row>
        <Row className={`${Classes.HeaderSectionContainer} mrsService` }>
          <Col
              sm={24}
              xs={24}
              md={24}
              lg={12}
              data-aos="fade-up"
              data-aos-delay="40"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out-cubic"
              className="image-about"
          >
            <div className={Classes.ImageContainer}>
              <MrsServiceImage></MrsServiceImage>
            </div>
          </Col>

          <Col 
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className={Classes.HeaderSectionContent}
            data-aos="fade-right"
            data-aos-delay="40"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            style={{ overflow: "hidden" }}
          >
            {" "}
            <p className={Classes.Paragraph}  style={{direction: 'rtl', textAlign: 'justify', fontFamily: 'Cairo'}}>
            يعد التواصل من خلال "الرسائل القصيرة والرسائل الصوتية" الأكثر إستخداما بين البشر، لذلك حرصت شركة MetaSign على تطوير تقنية MRS (Message Relay Service) وهي تعتبر الخدمة الأولى في العالم التي تمكن إخواننا
متحدثي لغة الإشارة من فئة الصم والبكم بالتواصل مع المجتمع عبر إرسال فيديو بلغة الإشارة ليتم ترجمته إلى رسالة
صوتية أو العكس عبر مترجمينا المعتمدين حتى يتسنى للمستخدم مشاركته مع من يحب عن طريق وسائل التواصل
الاجتماعي.
            </p>
          </Col>

        </Row>
      </div>
    </div>
    :
    <div className="App-Container" id="mrsService">
    <div
      className={`${Classes.WhoAreWeSection} `}
      id="mrsService"
    >
      <Row
      className={`${Classes.HeaderSectionParagraph}` } >
        <p style={{fontSize: '32px'}}>
          {" "}
          MRS Service
        </p>
      </Row>
      <Row className={`${Classes.HeaderSectionContainer} mrsService` }>
        <Col
            sm={24}
            xs={24}
            md={24}
            lg={12}
            data-aos="fade-up"
            data-aos-delay="40"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out-cubic"
            className="image-about"
        >
          <div className={Classes.ImageContainer}>
            <MrsServiceImage></MrsServiceImage>
          </div>
        </Col>

        <Col 
          sm={24}
          xs={24}
          md={24}
          lg={12}
          className={Classes.HeaderSectionContent}
          data-aos="fade-right"
          data-aos-delay="40"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out-cubic"
          style={{ overflow: "hidden" }}
        >
          {" "}
          <p className={Classes.Paragraph}  style={{textAlign: 'justify', fontFamily: 'Cairo'}}>
          Communication through "SMS and Voice Messages" is the most widely used among humans, so
MetaSign has developed MRS technology (Message Relay Service), which is the first service in the world
that enables deaf and sign language speakers to communicate with the community by sending a video In
sign language to be translated into a voice message or vice versa through our certified translators so that
the user can share it with family and friends through social media.
          </p>
        </Col>

      </Row>
    </div>
  </div>
  );
};

export default MrsService;
