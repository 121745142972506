
import vision from '../icons/subscriptions.png';

const SubscriptionImage = () => {
  return (
    <img src={vision}
    style = {{
      width: '75%',
      borderRadius: '30px'
    }}
    />
  );
};

export default SubscriptionImage;
