const GooglePlay = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.312796 0.255676C0.0787867 0.488723 0 0.877523 0 1.34479V18.5328C0 18.9989 0.156398 19.3889 0.390408 19.6219L0.468019 19.6992L10.1518 10.0553V9.89958L0.312796 0.255676Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.2763 13.3217L10.0742 10.1329V9.89981L13.2763 6.71094L13.3539 6.78823L17.1804 8.96528C18.274 9.58713 18.274 10.5989 17.1804 11.2208L13.2763 13.3217Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M13.3555 13.2439L10.0758 9.97778L0.314453 19.699C0.704861 20.0878 1.25167 20.0878 1.95487 19.7763L13.3555 13.2439Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M13.3547 6.71085L1.95292 0.255817C1.24971 -0.132984 0.702908 -0.0556921 0.3125 0.333109L10.0739 9.97701L13.3547 6.71085Z"
        fill="url(#paint3_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5.07692"
          y1="0.199862"
          x2="5.07692"
          y2="19.5543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#267CAF" />
          <stop offset="0.286" stopColor="#4A92B4" />
          <stop offset="0.767" stopColor="#79BCBA" />
          <stop offset="1" stopColor="#88CEBC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="15.1281"
          y1="7.64175"
          x2="12.7743"
          y2="12.9878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F48879" />
          <stop offset="0.119" stopColor="#F5907A" />
          <stop offset="0.713" stopColor="#FBB777" />
          <stop offset="1" stopColor="#FDC874" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="11.6037"
          y1="11.7004"
          x2="0.974129"
          y2="20.332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB413D" />
          <stop offset="0.167" stopColor="#D94452" />
          <stop offset="0.575" stopColor="#AF487A" />
          <stop offset="0.862" stopColor="#954B92" />
          <stop offset="1" stopColor="#894A9C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="1.69546"
          y1="0.725048"
          x2="11.8651"
          y2="8.52308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54C0AC" />
          <stop offset="1" stopColor="#DDE79A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GooglePlay;
