import ApiService from "../../utils/api/api-service";
import { Contact_Request } from "../models/Contact";

class Contact extends ApiService {
  constructor(config?: any) {
    super({
      baseURL: `https://api.metasignapp.com/api/services/app/ContactUsRequest/Create`,
      ...config,
    });
  }
  public async postContact(conatct: Contact_Request): Promise<any> {
    console.log(conatct);
    return this.post("", conatct);
  }
}

export const contact = new Contact();
