
import mrsService from '../icons/mrsService.png';

const MrsServiceImage = () => {
  return (
    <img src={mrsService}
    style = {{
      // width: '100%',
      borderRadius: '30px',
      width:'428',
      height:'461'
    }}
    />
  );
};

export default MrsServiceImage;
