import { Row, Col, Button, Image } from "antd";
import Link from "antd/lib/typography/Link";
import Logo from "../icons/Logo";
import Classes from "./navbar.module.css";
import "./navbar.css";
import i18n from "i18next";
import { useEffect } from "react";

const Navbar = () => {
  // const { t, i18n } = useTranslation()

  const changeLanguageHandler = () => {
    localStorage.setItem("current_language", "ar");
    window.location.reload();
  };
  const changeLanguageHandlerEn = () => {
    localStorage.setItem("current_language", "en");
    window.location.reload();
  };

  return i18n.language === "ar" ? (
    <div className="App-Container">
      <Row className={Classes.navbarRow}>
        <Col lg={6} sm={24} className={`${Classes.navbarContact} font`}>
          <p>+965 55077018</p>
          <Link href="#contact" className={Classes.navbarContactButton}>
            <p>تواصل معنا</p>
          </Link>

          <Link
            onClick={() => changeLanguageHandlerEn()}
            className={Classes.navbarChangeLanguageButton}
          >
            <p>English</p>
          </Link>
        </Col>

        <Col lg={12} sm={24} className={Classes.navbarLink}>
          <Link href="#Subscription" className={Classes.anttypography}>
            <p className={`${Classes.navbarLinkPharagraph} font`}>الاشتراكات</p>
          </Link>
          <Link
            href="#mrsService"
            className={`${Classes.anttypography} mrsService`}
          >
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ direction: "rtl" }}
            >
              خدمة MRS
            </p>
          </Link>
          <Link href="#howWork" className={Classes.anttypography}>
            <p className={`${Classes.navbarLinkPharagraph} font`}>
              حول التطبيق
            </p>
          </Link>
          <Link href="#ourGoal" className={`${Classes.anttypography} ourGoal`}>
            <p className={`${Classes.navbarLinkPharagraph} font`}>هدفنا</p>
          </Link>
          <Link
            href="#ourVision"
            className={`${Classes.anttypography} ourVision`}
          >
            <p className={`${Classes.navbarLinkPharagraph} font`}>رؤيتنا</p>
          </Link>
          <Link
            href="#whoWeAre"
            className={`${Classes.anttypography} whoWeAre`}
          >
            <p className={`${Classes.navbarLinkPharagraph} font`}>من نحن</p>
          </Link>
          <Link href="#features" className={`${Classes.anttypography}`}>
            <p className={`${Classes.navbarLinkPharagraph} font features`}>
              ميزات النظام
            </p>
          </Link>
          <Link href="#Home" className={Classes.anttypography}>
            <p className={`${Classes.navbarLinkPharagraph} font`}>الرئيسية</p>
          </Link>
        </Col>

        <Col lg={4} sm={24} className={Classes.navbarRowImage}>
          <Logo height={75} width={75} />
        </Col>
      </Row>
    </div>
  ) : (
    <div className="App-Container">
      <Row className={Classes.navbarRow}>
        <Col lg={6} sm={24} className={`${Classes.navbarContact} font`}>
          <p>+965 55077018</p>
          <Link href="#contact" className={Classes.navbarContactButton}>
            <p> Contact Us</p>
          </Link>

          <Link
            onClick={() => changeLanguageHandler()}
            className={Classes.navbarChangeLanguageButton}
          >
            <p>العربية</p>
          </Link>
        </Col>

        <Col lg={12} sm={24} className={Classes.navbarLink}>
          <Link href="#Home" className={Classes.anttypography}>
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              Main
            </p>
          </Link>
          <Link href="#features" className={`${Classes.anttypography}`}>
            <p
              className={`${Classes.navbarLinkPharagraph} font features`}
              style={{ fontSize: "13px" }}
            >
              {" "}
              Features
            </p>
          </Link>
          <Link
            href="#whoWeAre"
            className={`${Classes.anttypography} whoWeAre`}
          >
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              {" "}
              Who Are We
            </p>
          </Link>
          <Link
            href="#ourVision"
            className={`${Classes.anttypography} ourVision`}
          >
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              Our Vision
            </p>
          </Link>
          <Link href="#ourGoal" className={`${Classes.anttypography} ourGoal`}>
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              Our Goal
            </p>
          </Link>
          <Link href="#howWork" className={Classes.anttypography}>
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              {" "}
              About App
            </p>
          </Link>
          <Link
            href="#mrsService"
            className={`${Classes.anttypography} mrsService`}
          >
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              {" "}
              MRS
            </p>
          </Link>
          <Link href="#Subscription" className={Classes.anttypography}>
            <p
              className={`${Classes.navbarLinkPharagraph} font`}
              style={{ fontSize: "13px" }}
            >
              Subscriptions
            </p>
          </Link>
        </Col>

        <Col lg={4} sm={24} className={Classes.navbarRowImage}>
          <Logo height={75} width={75} />
        </Col>
      </Row>
    </div>
  );
};

export default Navbar;
