import { Row, Col, Button, Image } from "antd";
import WhoAreWeImage from "../icons/WhoAreWeImage";
import Classes from "./whoAreWe.module.css";
import './whoweare.css';
import i18n  from "i18next";

const WhoAreWe = () => {
  return( 
    i18n.language === 'ar'?

    <div className="App-Container" id="whoWeAre">
      <div
        className={`${Classes.WhoAreWeSection} `}
        id="WhoAreWe"
      >
        <Row
        className={`${Classes.HeaderSectionParagraph}` } >
          <p style={{fontSize: '32px'}}>
            {" "}
            من نحن
          </p>
        </Row>
        <Row className={`${Classes.HeaderSectionContainer} whoweare` }>
          
          <Col 
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className={Classes.HeaderSectionContent}
            data-aos="fade-right"
            data-aos-delay="40"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            style={{ overflow: "hidden" }}
          >
            {" "}
            <p className={Classes.Paragraph}  style={{direction: 'rtl', textAlign: 'justify', fontFamily: 'Cairo'}}>
                شركة كويتية بسواعد شبابية، تقدم خدمة فريدة من نوعها لأول مرة في الكويت عبارة عن تطبيق يدمج عالم لغة الإشارة في الحياة العملية ويقدم خدمات تصل إلى ذوي الاحتياجات الخاصة ومن لديهم صعوبة في السمع والكلام عن طريق الهاتف الذي بدوره أصبح الوسيلة الأسهل للوصول إلى كل ما يحتاج الشخص من خدمات في الوزارات والمؤسسات الحكومية، الوسيط فيها مجموعة من مترجمي لغة الإشارة الكويتيين.
            </p>
          </Col>
          <Col
            sm={24}
            xs={24}
            md={24}
            lg={12}
            data-aos="fade-up"
            data-aos-delay="40"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out-cubic"
            className="image-about"
          >
            <div className={Classes.ImageContainer}>
              <WhoAreWeImage></WhoAreWeImage>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    :
    <div className="App-Container" id="whoWeAre">
      <div
        className={`${Classes.WhoAreWeSection} `}
        id="WhoAreWe"
      >
        <Row
        className={`${Classes.HeaderSectionParagraph}` } >
          <p style={{fontSize: '32px'}}>
            {" "}
            Who are We
          </p>
        </Row>
        <Row className={`${Classes.HeaderSectionContainer} whoweare` }>
          
          <Col 
            sm={24}
            xs={24}
            md={24}
            lg={12}
            className={Classes.HeaderSectionContent}
            data-aos="fade-right"
            data-aos-delay="40"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            style={{ overflow: "hidden" }}
          >
            {" "}
            <p className={Classes.Paragraph}  style={{ textAlign: 'justify', fontFamily: 'Cairo'}}>
            A Kuwaiti company with youthful hands, offering a unique service for the first time in Kuwait and the
world. It is an application that integrates the world of sign language into working life and provides
services that reach people with special needs and those who have difficulty hearing and speaking via the
phone, which in turn has become the easiest way to reach everything they need. The person receives
services in ministries and government institutions, mediated by a group of Kuwaiti sign language
interpreters
            </p>
          </Col>
          <Col
            sm={24}
            xs={24}
            md={24}
            lg={12}
            data-aos="fade-up"
            data-aos-delay="40"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out-cubic"
            className="image-about"
          >
            <div className={Classes.ImageContainer}>
              <WhoAreWeImage></WhoAreWeImage>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
  
};

export default WhoAreWe;
